import { invokeApi } from "../../bl_libs/invokeApi";

export const updateMissionControlApi = async (data) => {
  const requestObj = {
    path: `api/consultant/update_mission_control_statement`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const dashboardApi = async (data, id) => {
  const requestObj = {
    path: `api/coach/dashboard/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const login = async (data) => {
  const requestObj = {
    path: `api/coach/login`,
    method: "POST",
    postData: data,
  };
  return invokeApi(requestObj);
};
export const loginAsCustomerApi = async (data) => {
  const requestObj = {
    path: `api/consultant/login_by_email`,
    method: "POST",
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const logout = async (token) => {
  const requestObj = {
    path: `api/coach/coach_logout`,
    method: "GET",
    headers: {
      "x-sh-auth": token,
    },
  };
  return invokeApi(requestObj);
};

export const register = async (data) => {
  const requestObj = {
    path: `api/user_registor.php`,
    method: "POST",
    headers: {
      // 'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const confirmEmail = async (data) => {
  const requestObj = {
    path: `api/coach/send_email_code`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const confirmPinCode = async (data) => {
  const requestObj = {
    path: `api/coach/verify_email_code`,
    method: "POST",
    headers: {
      // 'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const updatePassword = async (data) => {
  const requestObj = {
    path: `api/coach/reset_password`,
    method: "POST",
    headers: {
      // 'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const profileDetail = async (data) => {
  const requestObj = {
    path: `api/user_profile/detail_profile.php`,
    method: "POST",
    headers: {
      // 'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const updateAdminPassword = async (data) => {
  const requestObj = {
    path: `api/update_password/update_password.php`,
    method: "POST",
    headers: {
      // 'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const updateProfile = async (data) => {
  const requestObj = {
    path: `api/user_profile/edit_profile.php`,
    method: "POST",
    headers: {
      // 'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const consultantSettingApi = async (id) => {
  // console.log(id, "checkapiid");
  const requestObj = {
    path: id ? `api/consultant_init/${id}` : "api/consultant_init",
    method: "GET",
    headers: {
      // 'x-sh-auth': localStorage.getItem('token')
    },
  };
  return invokeApi(requestObj);
};
export const consultantMetaApi = async (data) => {
  const requestObj = {
    path: "api/configuration/get_meta_data_by_user_type",
    method: "POST",
    headers: {
      // 'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const consultantProfileApi = async (id, token) => {
  const requestObj = {
    path: `api/consultant/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": token ? token : localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const login_by_admin_api = async (data) => {
  const requestObj = {
    path: `api/coach/login_impersonate_user`,
    method: "GET",
    headers: {
      "x-sh-auth": data,
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const updatePrompters = async (data) => {
  const requestObj = {
    path: `api/coach_configuration/update_prompter_status`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_feed_image_on_s3 = async (data) => {
  const requestObj = {
    path: `api/post/update_image_on_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const regitrationVerification = (token) => {
  const requestObj = {
    path: `api/coach/verify_token`,
    method: "GET",
    headers: {
      "x-sh-auth": token,
    },
  };
  return invokeApi(requestObj);
};
export const unsubscribeApi = (token) => {
  const requestObj = {
    path: `app/block_email_service/${token}`,
    method: "GET",
    headers: {
      "x-sh-auth": token,
    },
  };
  return invokeApi(requestObj);
};
export const makeProfile = (data, token) => {
  const requestObj = {
    path: `api/coach/make_profile`,
    method: "PUT",
    headers: {
      "x-sh-auth": token,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_feed_video_on_s3 = async (data) => {
  const requestObj = {
    path: `app/upload_file_on_s3_with_dir`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
