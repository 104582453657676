// here we add all base urls and keys

// Base URLS
// export const baseUri = "http://192.168.0.160:4615/";
// export const s3baseUrl = process.env.REACT_APP_PIC_BASE_URL;

// Keys
// export const secret_key = process.env.REACT_APP_API_SECRET_KEY;
// here we add all base urls and keys
import {
  _get_consultant_data_from_localStorage,
  _get_consultant_from_localStorage,
} from "src/DAL/localstorage/LocalStorage";
import { update_root_value } from "src/utils/domUtils";
const get_consultant_data = _get_consultant_data_from_localStorage();
// Base URLS
export const baseUri = process.env.REACT_APP_API_BASE_URL;
export const baseMultilanguageUri =
  process.env.REACT_APP_MULTILANGUAGE_API_BASE_URL;
export const builderbaseUri = process.env.REACT_APP_API_BASE_BUILDER_URL;
export const vissioon_website_URL =
  process.env.REACT_APP_VISSIOON_WBSITE_BASE_URL;

export const s3baseUrl = process.env.REACT_APP_PIC_BASE_URL;
export const builderTemplatebaseUrl =
  process.env.REACT_APP_BUILDER_TEMPLATE_S3_BASE_URL;
export const builderTemplatebaseUrlForS3Files =
  process.env.REACT_APP_BUILDER_TEMPLATE_S3_BASE_URL_S3_FILES;
export const builder_S3_baseUrl = process.env.REACT_APP_BUILDER_S3_BASE_URL;
export const builder_baseUrl = process.env.REACT_APP_BUILDER_BASE_URL;
export const websiteUrl = process.env.REACT_APP_WEBSITE_URL;
export const client_url = process.env.REACT_APP_CLIENT_URL;
export const delegate_Url = process.env.REACT_APP_DELEGATE_URL;
export const clientPortalUrl = process.env.REACT_APP_CLIENT_PORTAL_URL;
export const communityWebBaseURL = process.env.REACT_APP_COMMUNITY_PORTAL_URL;
export const project_name = process.env.REACT_APP_PROJECT_NAME;
// export const socketBaseUri = "http://192.168.0.160:4615/";
export const socketBaseUri = process.env.REACT_APP_API_SOCKET_BASE_URL;
export const projectMode = process.env.REACT_APP_PROJECT_MODE;
export const builderApiBaseUrl = process.env.REACT_APP_BUILDER_API_BASE_URL;

export const domain_ip_address = process.env.REACT_APP_DOMAIN_IP_ADDRESS;
export const editor_key = get_consultant_data?.editor_keys
  ? get_consultant_data.editor_keys
  : "3jmedun4pgoaxi81hj80ay36v19yc7ej1ycnjq5k9b4w26y5";
export const portalWebsiteUrl = process.env.REACT_APP_PORTAL_WEBSITE_URL;
export const shopWebsiteUrl = process.env.REACT_APP_SHOP_WEBSITE_URL;

const get_consultant = _get_consultant_from_localStorage();

export const material_theme_palate = {
  lighter: "#d4bff2",
  light: "#ede7f6",
  main: "#1a93a9",
  dark: "#0059b3",
  darker: "#0059b3",
  contrastText: "#fff",
};
console.log(baseUri, "baseMultilanguageUribaseMultilanguageUri");
export const OwnerName = () => {
  if (project_name == "DD") {
    return "Kim";
  } else if (project_name == "HINA_KHAN") {
    return "Hina";
  } else if (project_name == "DANIELLE") {
    return "Danielle";
  } else if (project_name == "PGI_DEV") {
    return "PGI";
  }
};

export const ownerName = OwnerName() + "'s";
export const ownerCompleteName = OwnerName();

export const get_app_logo = () => {
  if (project_name == "DD") {
    return get_consultant?.brand_logo;
  } else if (project_name == "HINA_KHAN") {
    return get_consultant?.brand_logo;
  } else if (project_name == "DANIELLE") {
    return get_consultant?.brand_logo;
  } else if (project_name == "BASIC_DEV") {
    return get_consultant?.brand_logo;
  } else if (project_name == "PGI_DEV") {
    return get_consultant?.brand_logo;
  }
};

export const get_app_headers = () => {
  if (project_name == "DD") {
    return {
      title: get_consultant?.meta_title,
      description: get_consultant?.meta_description,
      fav_icon: get_consultant?.brand_favicon,
    };
  } else if (project_name == "HINA_KHAN") {
    return {
      title: get_consultant?.meta_title,
      description: get_consultant?.meta_description,
      fav_icon: get_consultant?.brand_favicon,
    };
  } else if (project_name == "DANIELLE") {
    return {
      title: get_consultant?.meta_title,
      description: get_consultant?.meta_description,
      fav_icon: get_consultant?.brand_favicon,
    };
  } else if (project_name == "BASIC_DEV") {
    return {
      title: get_consultant?.meta_title,
      description: get_consultant?.meta_description,
      fav_icon: get_consultant?.brand_favicon,
    };
  } else if (project_name == "PGI_DEV") {
    return {
      title: get_consultant?.meta_title,
      description: get_consultant?.meta_description,
      fav_icon: get_consultant?.brand_favicon,
    };
  }
};

if (project_name == "DD") {
  material_theme_palate.main = "#f6bd4b";
} else {
  material_theme_palate.main = "#1a93a9";
}

if (project_name == "DD") {
  material_theme_palate.main = "#06c";
} else if (project_name == "BASIC_DEV") {
  material_theme_palate.main = "#f6bd4b";
  /* Main theme color */
  update_root_value("--portal-theme-primary", "#06c");
  update_root_value("--portal-theme-secondary", "rgb(255 218 0 / 8%)");
  update_root_value("--text-primary-color", "white");
  update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
  /* Main theme color end */

  /* Main background color*/
  update_root_value("--background-primary-color", "black");
  update_root_value("--background-secondary-color", "#1d1c1d");

  /* Main background color end*/

  /* Sidebar Properties */
  update_root_value("--sidebars-text-color", "black");
  update_root_value("--sidebars-active-color", "#06c");
  update_root_value("--sidebars-hover-color:", "#06c");
  update_root_value("--sidebars-background-color", "#1d1c1d");
  /* Sidebar Properties */

  /* Popup / Popover properties  */
  update_root_value("--popup-background-color", "#1d1c1d");
  update_root_value("--popup-text-color", "white");
  /* Popup / Popover properties end  */

  /* Element properties */
  update_root_value("--input-text-color", "#fff");
  update_root_value("--input-placeholder-color", "#fff");
  update_root_value("--input-background-primary", "#1d1c1d");
  update_root_value("--input-background-secondary", "#1d1c1d");
  update_root_value(" --icons-color-allover", "#f6bd4b");
  /* Element properties ends*/

  /* Button Properties */

  update_root_value("--button-background-color", "#1c1c1c");
  update_root_value("--button-text-color", "#f6bd4b");
  update_root_value("--button-outlined-color", "#f6bd4b");
  update_root_value("--button-hover-color", "#f6bd4b");

  /* Button Properties ends*/

  /* Audio Properties */
  update_root_value("--audio-color", "#F1F3F4");
  /* Audio Properties ends*/

  /* calendar Properties */
  update_root_value("--calendar-selected-box", "#8b8b8b");
  /* calendar Properties ends*/
} else if (project_name == "PGI_DEV") {
  material_theme_palate.main = "#f6bd4b";
  /* Main theme color */
  update_root_value("--portal-theme-primary", "#f6bd4b");
  update_root_value("--portal-theme-secondary", "rgb(255 218 0 / 8%)");
  update_root_value("--text-primary-color", "white");
  update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
  /* Main theme color end */

  /* Main background color*/
  update_root_value("--background-primary-color", "black");
  update_root_value("--background-secondary-color", "#1d1c1d");

  /* Main background color end*/

  /* Sidebar Properties */
  update_root_value("--sidebars-text-color", "white");
  update_root_value("--sidebars-active-color", "#f6bd4b");
  update_root_value("--sidebars-hover-color:", "#f6bd4b");
  update_root_value("--sidebars-background-color", "#1d1c1d");
  /* Sidebar Properties */

  /* Popup / Popover properties  */
  update_root_value("--popup-background-color", "#1d1c1d");
  update_root_value("--popup-text-color", "white");
  /* Popup / Popover properties end  */

  /* Element properties */
  update_root_value("--input-text-color", "#fff");
  update_root_value("--input-placeholder-color", "#fff");
  update_root_value("--input-background-primary", "#1d1c1d");
  update_root_value("--input-background-secondary", "#1d1c1d");
  update_root_value(" --icons-color-allover", "#f6bd4b");
  /* Element properties ends*/

  /* Button Properties */

  update_root_value("--button-background-color", "#1c1c1c");
  update_root_value("--button-text-color", "#f6bd4b");
  update_root_value("--button-outlined-color", "#f6bd4b");
  update_root_value("--button-hover-color", "#f6bd4b");

  /* Button Properties ends*/

  /* Audio Properties */
  update_root_value("--audio-color", "#F1F3F4");
  /* Audio Properties ends*/

  /* calendar Properties */
  update_root_value("--calendar-selected-box", "#8b8b8b");
  /* calendar Properties ends*/
} else if (project_name == "HINA_KHAN") {
  material_theme_palate.main = "#1a93a9";
  /* Main theme color */
  update_root_value("--portal-theme-primary", "#1a93a9");
  update_root_value("--portal-theme-secondary", "rgb(0 171 85 / 8%)");
  update_root_value("--text-primary-color", "black");
  update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
  /* Main theme color end */

  /* Main background color*/
  update_root_value("--background-primary-color", "white");
  update_root_value("--background-secondary-color", "white");

  /* Main background color end*/

  /* Sidebar Properties */
  update_root_value("--sidebars-text-color", "black");
  update_root_value("--sidebars-active-color", "#1a93a9");
  update_root_value("--sidebars-hover-color", "#1a93a9");
  update_root_value("--sidebars-background-color", "white");
  /* Sidebar Properties */

  /* Popup / Popover properties  */
  update_root_value("--popup-background-color", "#fff");
  update_root_value("--popup-text-color", "black");
  /* Popup / Popover properties end  */

  /* Element properties */
  update_root_value("--input-text-color", "black");
  update_root_value("--input-placeholder-color", "black"); //not yet
  update_root_value("--input-background-primary", "#fff"); //not yet
  update_root_value("--input-background-secondary", "#EAEAEA");
  update_root_value(" --icons-color-allover", "#f6bd4b");
  /* Element properties ends*/

  /* Button Properties */
  update_root_value("--button-background-color", "#1a93a9");
  update_root_value("--button-text-color", "#fff");
  update_root_value("--button-outlined-color", "#1a93a9");
  update_root_value("--button-hover-color", "#1a93a9");
  /* Button Properties ends*/

  /* Audio Properties */
  update_root_value("--audio-color", "#F1F3F4");
  /* Audio Properties ends*/

  /* calendar Properties */
  update_root_value("--calendar-selected-box", "#8b8b8b");
  /* calendar Properties ends*/
} else if (project_name == "DANIELLE") {
  material_theme_palate.main = "#1a93a9";
  /* Main theme color */
  update_root_value("--portal-theme-primary", "#1a93a9");
  update_root_value("--portal-theme-secondary", "rgb(0 171 85 / 8%)");
  update_root_value("--text-primary-color", "black");
  update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
  /* Main theme color end */

  /* Main background color*/
  update_root_value("--background-primary-color", "white");
  update_root_value("--background-secondary-color", "white");

  /* Main background color end*/

  /* Sidebar Properties */
  update_root_value("--sidebars-text-color", "black");
  update_root_value("--sidebars-active-color", "#1a93a9");
  update_root_value("--sidebars-hover-color", "#1a93a9");
  update_root_value("--sidebars-background-color", "white");
  /* Sidebar Properties */

  /* Popup / Popover properties  */
  update_root_value("--popup-background-color", "#fff");
  update_root_value("--popup-text-color", "black");
  /* Popup / Popover properties end  */

  /* Element properties */
  update_root_value("--input-text-color", "black");
  update_root_value("--input-placeholder-color", "black"); //not yet
  update_root_value("--input-background-primary", "#fff"); //not yet
  update_root_value("--input-background-secondary", "#EAEAEA");
  update_root_value(" --icons-color-allover", "#f6bd4b");
  /* Element properties ends*/

  /* Button Properties */
  update_root_value("--button-background-color", "#1a93a9");
  update_root_value("--button-text-color", "#fff");
  update_root_value("--button-outlined-color", "#1a93a9");
  update_root_value("--button-hover-color", "#1a93a9");
  /* Button Properties ends*/

  /* Audio Properties */
  update_root_value("--audio-color", "#F1F3F4");
  /* Audio Properties ends*/

  /* calendar Properties */
  update_root_value("--calendar-selected-box", "#8b8b8b");
  /* calendar Properties ends*/
} else {
  //DD theme in else
  material_theme_palate.main = "#f6bd4b";
  /* Main theme color */
  update_root_value("--portal-theme-primary", "#f6bd4b");
  update_root_value("--portal-theme-secondary", "rgb(255 218 0 / 8%)");
  update_root_value("--text-primary-color", "white");
  update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
  /* Main theme color end */

  /* Main background color*/
  update_root_value("--background-primary-color", "black");
  update_root_value("--background-secondary-color", "#1d1c1d");

  /* Main background color end*/

  /* Sidebar Properties */
  update_root_value("--sidebars-text-color", "white");
  update_root_value("--sidebars-active-color", "#f6bd4b");
  update_root_value("--sidebars-hover-color:", "#f6bd4b");
  update_root_value("--sidebars-background-color", "#1d1c1d");
  /* Sidebar Properties */

  /* Popup / Popover properties  */
  update_root_value("--popup-background-color", "#1d1c1d");
  update_root_value("--popup-text-color", "white");
  /* Popup / Popover properties end  */

  /* Element properties */
  update_root_value("--input-text-color", "#fff");
  update_root_value("--input-placeholder-color", "#fff");
  update_root_value("--input-background-primary", "#1d1c1d");
  update_root_value("--input-background-secondary", "#1d1c1d");
  update_root_value(" --icons-color-allover", "#f6bd4b");
  /* Element properties ends*/

  /* Button Properties */

  update_root_value("--button-background-color", "#1c1c1c");
  update_root_value("--button-text-color", "#f6bd4b");
  update_root_value("--button-outlined-color", "#f6bd4b");
  update_root_value("--button-hover-color", "#f6bd4b");

  /* Button Properties ends*/

  /* Audio Properties */
  update_root_value("--audio-color", "#F1F3F4");
  /* Audio Properties ends*/

  /* calendar Properties */
  update_root_value("--calendar-selected-box", "#8b8b8b");
  /* calendar Properties ends*/
}
