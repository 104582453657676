import { invokeApi } from "../../bl_libs/invokeApi";
export const paymentPlansListBySalePageApi = async (id) => {
  const requestObj = {
    path: `api/payment_plan/list_payment_plan_by_consultant/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const paymentPlansListApi = async (data) => {
  const requestObj = {
    path: `api/payment_plan/list_payment_plan`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const detailPaymentPlanApi = async (id) => {
  const requestObj = {
    path: `api/payment_plan/detail_payment_plan/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const editPaymentPlanApi = async (data, id) => {
  const requestObj = {
    path: `api/payment_plan/edit_payment_plan/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addPaymentPlanApi = async (data) => {
  const requestObj = {
    path: `api/payment_plan/add_payment_plan`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deletePaymentPlanApi = async (id) => {
  const requestObj = {
    path: `api/payment_plan/delete_payment_plan/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const paymentPlansApi = async () => {
  const requestObj = {
    path: `app/get_plan_for_coach`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const admin_payment_plan_details = async (id) => {
  const requestObj = {
    path: `api/web_plan/detail_payment_plan/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getSubscriptionApi = async (id, mode) => {
  const requestObj = {
    path: `api/coach/get_coach_subscription/${id}?mode=${mode}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const changeCard = async (data) => {
  const requestObj = {
    path: `api/consultant/change_stripe_card`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addnewCard = async (data) => {
  const requestObj = {
    path: `api/coach/change_coach_card`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addSubscription = async (data) => {
  const requestObj = {
    path: `api/coach/upgrade_coach_plan`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const cancleSubscription = async (data) => {
  const requestObj = {
    path: `api/coach/cancel_coach_subscription`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const confirmIncompleteSubscription = async (data) => {
  const requestObj = {
    path: `app/confirm_one_time_payment`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

// Plan configuration

export const get_payment_plan_configration = async (coachId) => {
  const requestObj = {
    path: `api/payment_plan_configration/get_payment_plan_configration/${coachId}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_payment_plan_configration = async (data) => {
  const requestObj = {
    path: `api/payment_plan_configration/update_payment_plan_configration`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const buy_template_api = async (data) => {
  const requestObj = {
    path: `app/buy_template`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const confirmIncompleteTemplatePayment = async (data) => {
  const requestObj = {
    path: `app/complete_template_payment`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

// payment with paypal

export const buy_template_with_paypal = async (data) => {
  const requestObj = {
    path: `app/buy_template_with_paypal`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const confirm_buy_template_with_paypal = async (data) => {
  const requestObj = {
    path: `app/complete_template_payment_with_paypal`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
